<template>
    <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Change user</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedUser"
                                outlined
                                dense
                                :items="users"
                                hide-selected
                                item-text="display_text"
                                :search-input.sync="userSearch"
                                return-object
                                label="Search for user"
                                placeholder="Enter user first name, last name or email"
                                :loading="isSearching"
                                clearable
                            >
                            <template v-slot:label>
                                User
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available </div>
                            </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    x-large
                    @click="saveUser()"
                    :loading="isLoading"
                    :disabled="!selectedUser"
                >
                Save
                </v-btn>
                <v-btn
                    x-large
                    text
                    @click="closeDialog">
                    Cancel
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import UniqueMemberService from "@/services/user/unique-member/UniqueMemberService";
    import CandidateService from "@/services/candidate/CandidateService";

    const user = new UniqueMemberService();
    const candidate = new CandidateService();

    export default{
        data(){
            return{
                dialog: false,
                candidateId: null,
                isSearching: false,
                users: [],
                selectedUser: '',
                userSearch: null,
                scoreId: null,
                isLoading: false
            }
        },
        methods:{
            closeDialog(){
                this.dialog = false;
            },
            openDialog(candidateId, scoreId){
                this.reset();
                this.scoreId = scoreId;
                this.candidateId = candidateId;
                this.dialog = true;
            },
            reset(){
                this.candidateId = null;
                this.users = [];
                this.selectedUser = '';
                this.userSearch = null;
                this.scoreId = null;
                this.isLoading = false;
            },
            saveUser(){
                this.$confirm({
                    message: `Are you sure? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                    if (confirm) {
                        this.isLoading = true;
                        let data = {
                            user_id: this.selectedUser.id
                        };

                        candidate
                        .changeUser(this.candidateId, data)
                        .then((response) => {
                            this.$emit('refresh');
                            this.closeDialog();
                            this.$snotify.success("User changed sucessfully ");
                        })
                        .catch((err) => {
                            this.$snotify.error("Something went wrong");
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                    }
                    },
                });
            }
        },
        watch:{
            userSearch(val) {
                user
                .search(val, this.scoreId)
                .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.first_name +' '+ user.last_name + " | " + user.email
                    this.users.push(data);
                    });
                })
                .catch((err) => {

                })
                .finally(() => (this.isLoading = false));
            }
        }
    }
</script>
