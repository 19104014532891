<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                Candidates
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Candidates
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="pt-4 px-3 mb-5" style="background: #ececec; border: 0px;">
              <v-row no-gutters>
                <v-col cols="12" md="8">
                  <p class="font-size-lg">
                    <strong>Full name:</strong> {{ user.full_name ? user.full_name : 'NA'}} <br>
                    <strong>UniqueID:</strong> {{ user.unique_identifier ? user.unique_identifier : 'NA' }} <br>
                    <strong>Score:</strong> {{ user.score_short_name ? user.score_short_name : 'NA' }}
                  </p>        
                </v-col>
                <v-col cols="12" md="4">
                  <p class="font-size-lg">
                    <strong>Email:</strong> 
                    <a class="text-info mb-1" :href="'mailto:'+user.email">
                          {{user.email ? user.email : 'NA' }}
                    </a>
                    <br>
                    <strong>Preferred phone:</strong> 
                    <a  class="text-info mb-1" :href="'tel:'+user.preferred_phone">
                      {{user.preferred_phone}}
                    </a>
                  </p>
                </v-col>
              </v-row>
            </div>
              
            <v-row class="mt-5">
              <v-col cols="12" md="7" offset-md="2">
                <v-row>
                  <v-col cols="12" md="6" offset="6">
                    <v-skeleton-loader
                      boilerplate
                      class="mt-30"
                      v-if="loading"
                      elevation="2"
                      max-width="394"
                      height="200"
                      type="card-header, article, actions"
                    ></v-skeleton-loader>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      boilerplate
                      v-if="loading"
                      elevation="2"
                      max-width="394"
                      height="200"
                      class="mt-10"
                      type="card-header, article, actions"
                    ></v-skeleton-loader>
                  </v-col>

                  <v-col cols="12" md="6" offset="6">
                    <v-skeleton-loader
                      boilerplate
                      v-if="loading"
                      elevation="2"
                      class="ml-9"
                      max-width="394"
                      height="200"
                      type="card-header, article, actions"
                    ></v-skeleton-loader>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      boilerplate
                      v-if="loading"
                      elevation="2"
                      max-width="394"
                      height="200"
                      class="mt-10 ml-9"
                      type="card-header, article, actions"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>

                <v-timeline v-if="candidates.length > 0">
                  <v-timeline-item
                    v-for="(item, index) in candidates"
                    dot-color="purple-lighten-2"
                    fill-dot
                  >
                    <v-card>
                      <v-card-title :style="{ background:getRandomColor() }">
                        <v-icon
                          size="large"
                          class="me-2"
                          icon="fa fa-magnify"
                        ></v-icon>
                        
                        <h5 class="text-white">
                          {{ item.full_name }}
                        </h5>
                        
                        <div class="text-white ml-2">
                          ({{ item.exam_key }})
                        </div>
                      </v-card-title>
                      <v-card-text class="pt-5">
                        <div class="font-size-lg">
                          <strong>Exam name:</strong>  
                          {{ item.exam_name }}
                        </div>
                        <div class="font-size-lg">
                          <strong>Product type:</strong>  
                          {{ item.product_type_name }}
                        </div>
                        <div class="font-size-lg">
                          <strong>Enrolment date:</strong>  
                          {{ item.formatted_enrolment_date }}
                        </div>
                        <div class="mt-2">
                          <div v-html="item.enrolment_status_badge"></div>
                          <div class="mt-2" v-html="item.exam_status_badge"></div>
                        </div>
                        <div class="mt-2">
                          <a class="navi-link font-size-lg" @click="changeUser(item.id, item.score_id)">
                            <span class="navi-icon">
                                <i class="fas fa-edit"></i>
                            </span>
                            <span class="navi-text">Change user</span>
                          </a>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>

                <div class="text-center font-size-lg" v-if="candidates.length == 0">
                  No candidates found
                </div>
              </v-col>
            </v-row>

          </div>
        </div>
      </div>

      <change-user ref="change-user" @refresh="getCandidates"></change-user>

    </div>
  </v-app>    
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import UniqueMemberService from "@/services/user/unique-member/UniqueMemberService";
import ChangeUser from "./ChangeUser";

const candidate = new CandidateService();
const user = new UniqueMemberService();

export default{
    components:{
      ChangeUser
    },
    data(){
        return{
            search:{

            },
            candidates: [],
            loading: false,
            total: null,
            perPage: null,
            page: null,
            user: '',
            matchingColors: [
              "#8DD6E9",
              "#FEAA1E",
              "#4BB5C1",
              "#E35B82",
              "#69C7A7",
              "#F39237",
              "#2D849A",
              "#D34E6F",
              "#54A390",
              "#F7A43B",
              "#479EB2",
              "#E74967",
              "#72D5AF",
              "#FAB442",
              "#398FAC",
              "#EB4A69",
              "#4DAE88",
              "#F58230",
              "#327C93",
              "#DA3D5C",
              "#62C6A6",
              "#FFA321",
              "#3B95B3",
              "#E93E63",
              "#80D7C2"
            ]
        }
    },
    methods:{
        getCandidates(){
            this.loading = true;

            candidate
            .getByUser(this.search, this.page, this.userId)
            .then((res) => {
                this.candidates = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(() => {
                this.loading = false;
            })
        },
        changeUser(candidateId, scoreId){
          this.$refs['change-user'].openDialog(candidateId, scoreId);
        },
        getUser(){
          user
          .show(this.userId)
          .then((res) => {
            this.user = res.data.user;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
        },
        getRandomColor(){
          // Generate a random index within the range of the array length
          const randomIndex = Math.floor(Math.random() * this.matchingColors.length);
          // Return the randomly selected color
          return this.matchingColors[randomIndex];
        }
    },
    mounted(){
        this.getCandidates();
        this.getUser();
    },
    computed:{
      userId(){
          return this.$route.params.userId;
      }
    }
}
</script>
